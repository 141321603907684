@mixin theme($scheme) {
  @media (prefers-color-scheme: $scheme) {
    @content;
  }
}

@mixin marginate {
  max-width: 120rem;
  margin: 0 auto;

  @media only screen and (max-width: 120rem) {
    width: 90%;
  }
}

@mixin addPadding {
  padding: 10rem 0;
}

@mixin sectionStyles($number) {
  padding: 10rem 0;

  @if ($number==odd) {
    @include marginate;
  }

  @if ($number==even) {
    background-color: $color-slate-dark-4;

    .container {
      width: 110rem;
      margin: 0 auto;

      @media only screen and (max-width: 110rem) {
        max-width: 90%;
      }
    }

    @include theme(dark) {
      background-color: $color-slate-dark-4;
    }
  }
}

/* MEDIA QUERY MANAGER
 *
 * 0 - 600px => phone
 * 600px - 900px => tablet portrait
 * 900px - 1200px => tablet landscape
 * 1200px+ => desktop
 */

/*
 * CHOICES:
 * phone, tab-port, tab-lan, desk
 */

@mixin respond($breakpoint) {
  @if ($breakpoint==phone) {
    @media only screen and (max-width: 37.5em) {
      //600px = 37.5em (1em = 16px)
      @content;
    }
  }

  @if ($breakpoint==phone-large) {
    @media only screen and (max-width: 50em) {
      //800px = 56.25em (1em = 16px)
      @content;
    }
  }

  @if ($breakpoint==tab-port) {
    @media only screen and (max-width: 56.25em) {
      //900px = 56.25em (1em = 16px)
      @content;
    }
  }

  @if ($breakpoint==tab-port-touch) {
    @media only screen and (max-width: 56.25em), only screen and (hover: none) {
      //900px = 56.25em (1em = 16px)
      @content;
    }
  }

  @if ($breakpoint==touchscreen) {
    @media only screen and (hover: none) {
      @content;
    }
  }

  @if ($breakpoint==tab-lan) {
    @media only screen and (max-width: 75em) {
      //1200px = 75em (1em = 16px)
      @content;
    }
  }

  @if ($breakpoint==huge) {
    @media only screen and (min-width: 112.5em) {
      //1800px = 112.5em (1em = 16px)
      @content;
    }
  }
}
