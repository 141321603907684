/* COLORS */

$color-primary-light: #8dfcde;
$color-primary-main: #6effd8;
$color-primary-dark: #3ff3c3;

$color-white: #ffffff;
$color-slate-light-1: #f0f0f0;
$color-slate-light-2: #dbdbdb;
$color-slate-light-3: #aaaaaa;
$color-slate-light-4: #8a8a8a;

$color-slate-dark-1: #323133;
$color-slate-dark-2: #242225;
$color-slate-dark-3: #1e1b20;
$color-slate-dark-4: #1a171e;
$color-slate-dark-5: #120e16;
$color-black: #000;

$color-tomato: #cf0000;
$color-emerald: #00ac56;

/* SHADOWS */
$shadow-primary-all: 0 0 1rem $color-primary-main;
$shadow-subtle: 0 0.3rem 1rem rgba($color-slate-dark-5, 0.2);
$shadow-subtle-small: 0 0.2rem 0.5rem rgba($color-slate-dark-5, 0.2);

$radius-small: 0.6rem;
$margin-medium: 4rem;

$font-default: 'Metropolis', sans-serif;
$font-mono: 'JetBrains Mono', 'Fira Code', monospace;
$font-icon: 'Icomoon', sans-serif;
$font-logo: 'Logo', sans-serif;
