@import '../../styles/mixins';
@import '../../styles/variables';

.about {
  position: relative;
  @include sectionStyles(even);

  &__content {
    display: grid;
    grid-template-columns: minmax(min-content, 65%) minmax(30%, 1fr);
    grid-template-rows: min-content;
    grid-gap: 2rem;
    margin-bottom: $margin-medium;
    justify-content: space-between;

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-template-rows: min-content 100%;
    }

    .description {
      margin-bottom: 2rem;
    }

    .profile {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      @include respond(phone) {
        max-height: 30rem;
      }
    }

    .image {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 95%;
      align-self: center;
      object-fit: contain;
      border-radius: $radius-small;
      border: 2px solid transparent;
      box-shadow: 0 0 1rem transparent;
      transition: all 0.4s;

      @include respond(tab-port) {
        min-height: 23rem;
        max-height: 50%;
      }

      @include respond(phone) {
        max-height: 100%;
        position: static;
        transform: none;
      }

      &:hover {
        border: 2px solid $color-primary-dark;
        box-shadow: 0 0 1rem $color-primary-dark;
      }
    }
  }

  .socialLinks {
    @include respond(phone) {
      text-align: center;
    }
  }
}
