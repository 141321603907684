@import '../../styles/mixins';
@import '../../styles/variables';

.projects {
  @include sectionStyles(even);

  .heading {
    text-align: center;
  }
}
