@import './variables';

.logo {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-logo !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 4rem;
  width: 4.5rem;
  height: 4.5rem;
  text-align: center;

  position: relative;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .tooltip {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: $font-mono;
    font-size: 1rem;
    background-color: $color-slate-dark-4;
    color: $color-white;
    padding: 0.3rem 0.6rem;
    border-radius: 0.3rem;
    box-shadow: 0 0 3px $color-primary-main;
    text-transform: capitalize;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;
  }

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
}

.logo-jestjsio-icon:before {
  content: '\e905';
  color: #99424f;
}
.logo-nestjs-icon:before {
  content: '\e906';
  color: #ea2845;
}
.logo-airtable .path1:before {
  content: '\e900';
  color: rgb(255, 204, 0);
}
.logo-airtable .path2:before {
  content: '\e901';
  margin-left: -1em;
  color: rgb(49, 194, 242);
}
.logo-airtable .path3:before {
  content: '\e902';
  margin-left: -1em;
  color: rgb(237, 48, 73);
}
.logo-airtable .path4:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(198, 40, 66);
}
.logo-auth0:before {
  content: '\e904';
  color: #eb5424;
}
.logo-css .path1:before {
  content: '\e90c';
  color: rgb(33, 150, 243);
}
.logo-css .path2:before {
  content: '\e90d';
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.logo-express:before {
  content: '\e90e';
}
.logo-figma .path1:before {
  color: rgb(51, 51, 51);
}
.logo-figma .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(10, 207, 131);
}
.logo-figma .path3:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(162, 89, 255);
}
.logo-figma .path4:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(242, 78, 30);
}
.logo-figma .path5:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(255, 114, 98);
}
.logo-figma .path6:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(26, 188, 254);
}
.logo-git:before {
  content: '\e919';
  color: #f34f29;
}
.logo-html .path1:before {
  content: '\e91b';
  color: rgb(227, 79, 38);
}
.logo-html .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(239, 101, 42);
}
.logo-html .path3:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(235, 235, 235);
}
.logo-html .path4:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.logo-java .path1:before {
  content: '\e91f';
  color: rgb(83, 130, 161);
}
.logo-java .path2:before {
  content: '\e920';
  margin-left: -0.740234375em;
  color: rgb(231, 111, 0);
}
.logo-java .path3:before {
  content: '\e921';
  margin-left: -0.740234375em;
  color: rgb(83, 130, 161);
}
.logo-java .path4:before {
  content: '\e922';
  margin-left: -0.740234375em;
  color: rgb(231, 111, 0);
}
.logo-java .path5:before {
  content: '\e923';
  margin-left: -0.740234375em;
  color: rgb(83, 130, 161);
}
.logo-javascript .path1:before {
  content: '\e924';
  color: rgb(240, 219, 79);
}
.logo-javascript .path2:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(50, 51, 48);
}
.logo-javascript-rounded .path1:before {
  content: '\e926';
  color: rgb(247, 223, 30);
}
.logo-javascript-rounded .path2:before {
  content: '\e927';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.logo-mongodb .path1:before {
  content: '\e928';
  color: rgb(67, 153, 52);
}
.logo-mongodb .path2:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(69, 165, 56);
}
.logo-mongodb .path3:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(70, 160, 55);
}
.logo-mongodb .path4:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(64, 148, 51);
}
.logo-mongodb .path5:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(79, 170, 65);
}
.logo-mongodb .path6:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(74, 167, 60);
}
.logo-mongodb .path7:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(87, 174, 71);
}
.logo-mongodb .path8:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(96, 178, 79);
}
.logo-mongodb .path9:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(169, 170, 136);
}
.logo-mongodb .path10:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(182, 181, 152);
}
.logo-mongodb .path11:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(194, 193, 167);
}
.logo-mongodb .path12:before {
  content: '\e933';
  margin-left: -1em;
  color: rgb(206, 205, 183);
}
.logo-mongodb .path13:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(219, 218, 199);
}
.logo-mongodb .path14:before {
  content: '\e935';
  margin-left: -1em;
  color: rgb(235, 233, 220);
}
.logo-mongodb .path15:before {
  content: '\e936';
  margin-left: -1em;
  color: rgb(206, 205, 183);
}
.logo-mongodb .path16:before {
  content: '\e937';
  margin-left: -1em;
  color: rgb(79, 170, 65);
}
.logo-mysql:before {
  content: '\e938';
  color: #00618a;
}
.logo-next-js:before {
  content: '\e939';
}
.logo-nodejs:before {
  content: '\e93a';
  color: #8cc84b;
}
.logo-php:before {
  content: '\e93b';
  color: #6181b6;
}
.logo-python .path1:before {
  content: '\e944';
  color: rgb(2, 119, 189);
}
.logo-python .path2:before {
  content: '\e945';
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.logo-react:before {
  content: '\e946';
  color: #61dafb;
}
.logo-sass:before {
  content: '\e947';
  color: #cb6699;
}

.logo-styled-components .path1:before {
  content: '\e963';
  color: rgb(237, 90, 129);
}
.logo-styled-components .path2:before {
  content: '\e964';
  margin-left: -1em;
  color: rgb(249, 105, 137);
}
.logo-styled-components .path3:before {
  content: '\e965';
  margin-left: -1em;
  color: rgb(244, 212, 78);
}
.logo-styled-components .path4:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(232, 200, 66);
}
.logo-styled-components .path5:before {
  content: '\e967';
  margin-left: -1em;
  color: rgb(244, 212, 78);
}
.logo-styled-components .path6:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(232, 200, 66);
}
.logo-styled-components .path7:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(244, 212, 78);
}
.logo-styled-components .path8:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(249, 105, 137);
}
.logo-styled-components .path9:before {
  content: '\e96b';
  margin-left: -1em;
  color: rgb(237, 90, 129);
}
.logo-styled-components .path10:before {
  content: '\e96c';
  margin-left: -1em;
  color: rgb(249, 105, 137);
}
.logo-styled-components .path11:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(237, 90, 129);
}
.logo-styled-components .path12:before {
  content: '\e96e';
  margin-left: -1em;
  color: rgb(232, 200, 66);
}
.logo-styled-components .path13:before {
  content: '\e96f';
  margin-left: -1em;
  color: rgb(249, 105, 137);
}
.logo-styled-components .path14:before {
  content: '\e970';
  margin-left: -1em;
  color: rgb(237, 90, 129);
}
.logo-styled-components .path15:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(249, 105, 137);
}
.logo-styled-components .path16:before {
  content: '\e972';
  margin-left: -1em;
  color: rgb(237, 90, 129);
}
.logo-typescript .path1:before {
  content: '\e973';
  color: rgb(49, 120, 198);
}
.logo-typescript .path2:before {
  content: '\e974';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.logo-figma .path1:before {
  content: '\e975';
  color: #0acf83;
}
.logo-figma .path2:before {
  content: '\e976';
  margin-left: -1em;
  color: #a259ff;
}
.logo-figma .path3:before {
  content: '\e977';
  margin-left: -1em;
  color: #f24e1e;
}
.logo-figma .path4:before {
  content: '\e978';
  margin-left: -1em;
  color: #ff7262;
}
.logo-figma .path5:before {
  content: '\e979';
  margin-left: -1em;
  color: #1abcfe;
}
