@import '../../../styles/mixins';
@import '../../../styles/variables';

.social {
  display: inline-flex;
  align-items: center;

  & > a {
    font-size: 2.2rem;
    display: block;
    text-decoration: none;
    color: $color-white;

    transition: all 0.2s;

    @include theme(dark) {
      color: $color-white;
    }

    &:not(:last-child) {
      margin-right: 1.8rem;
    }

    &:hover {
      color: $color-primary-light;
      filter: drop-shadow(0 0 1rem $color-primary-dark);

      @include theme(dark) {
        color: $color-primary-light;
      }
    }
  }
}
