@import '../../styles/variables';
@import '../../styles/mixins';

.link {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
  display: inline-block;
  color: $color-primary-light;
  transition: 0.2s all;
  position: relative;

  @include theme(dark) {
    color: $color-primary-light;
  }

  &::after {
    content: '';
    display: block;
    bottom: -0.5rem;
    height: 1px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    background-color: $color-primary-dark;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover {
    color: $color-primary-light !important;

    @include theme(dark) {
      color: $color-primary-light !important;
    }

    &::after {
      transform: scaleX(1);
    }
  }

  &:hover {
    color: $color-primary-light;
  }

  &:not(:last-child) {
    margin-right: 3rem;
  }
}
