@import '../../styles/mixins';
@import '../../styles/variables';

.row {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid $color-primary-dark;
  border-radius: $radius-small;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $color-slate-dark-2;

    @include theme(dark) {
      background-color: $color-slate-dark-2;
    }
  }

  .main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .title {
      flex: 1 1 65%;

      @include respond(phone) {
        flex: 1 1 100%;
      }
    }

    .type,
    .tech {
      flex: 1 1 25%;
      font-family: $font-mono;
      font-size: 1.2rem;
    }

    .type {
      text-align: left;
    }

    .tech {
      text-align: right;
    }

    @include respond(phone) {
      .type,
      .tech {
        margin-top: 0.5rem;
        text-align: left;
      }

      .type {
        flex-basis: 60%;
      }

      .tech {
        flex-basis: 40%;
      }
    }
  }

  .additional {
    max-height: 20rem;
    overflow: hidden;
    transition: all 0.3s;

    .description {
      padding: 2rem 0;
      font-size: 1.6rem;
    }

    .actions {
      display: flex;

      & > a {
        margin-right: 2rem;

        & > span {
          margin-right: 1rem;
        }
      }
    }
  }
}
